var $ = require('jquery');
var jquery = require('jquery');
require('parallax');
require('waypoints');



$( function(){
	var menuOpen;



	//testytest
	$(".menu-btn").click(function(event) {
			event.preventDefault();
    		$("#menu").attr("aria-hidden", "false");
	        $("#menu-menu-2").addClass("show");
	        $("body").addClass("no-scroll");
	        $("#tab-target").focus();
	        menuOpen = true;

	    	if ($("#menu").attr("aria-expanded")){
	    		$("#menu").attr("aria-expanded", "true")
	    	} else {
	    		$("#menu").attr("aria-expanded", "false")
	    	}

	    	
    });

    $(document).click(function(event) {
	    if (!$(event.target).closest(".menu-btn, .left").length) {
	        $("#menu-menu-2").removeClass("show");
	        $("body").removeClass("no-scroll");
	        $("#menu").attr("aria-expanded", "false");
	        $("#menu").attr("aria-hidden", "true");
	        menuOpen = false;
	    } 
	});

	document.addEventListener("focus", function(event) {

	    var menu = document.getElementById("menu-menu-2");

	    if (menuOpen && !menu.contains(event.target)) {
	        event.stopPropagation();
	        $("#tab-target").focus();
	        console.log('hwhw');
	    }

	}, true);

	function navBarDown() {
		$('#menu-container').css({position: "fixed",top: "0"});
	};

	function navBarUp() {
		$('#menu-container').css({position: "absolute", top: "auto"});
	};

	var nav = new Waypoint({
	  element: document.getElementById('heading'),
	  handler: function(direction) {
	  	if(direction == 'down'){
	  		navBarDown()
	  	}
	  	if(direction == 'up'){
	  		navBarUp()
	  	}
	  },
	  offset: 1
	});

});

